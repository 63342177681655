import React from "react"
import "../styles/global.css";
import Layout from "../components/layout"

export default function Home() {
    return <Layout>
        <div class="contact-wrapper">
            <div class="content">
                <h3>Sorry, we couldn't find that page. If you need help, give us a call on <a href="tel:0883873833">(08) 8387 3833</a></h3>
            </div>
        </div>
    </Layout >
}
